.returnRutas{ 
    height: 8vh;
    width: auto
}

.returnRutas:hover{
    cursor: pointer !important;
}

.contentRutaMain{
    padding-top:10vh;
    padding-left:5rem;
    padding-right:5rem;
    padding-bottom:5vh;
}

/*mobile*/
@media screen and (max-width: 600px) {
    .contentRutaMain{
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}


.informacionRuta{
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
}

.dificultadRuta{
    width: auto;
    height: 100px;
}

.submenuRuta{
    background-color: #e4e4df !important;
    height: 100%;
    color: #6e5b4b;
    text-align: center;
    padding-top: 1rem;
    font-family: Bebas Neue,cursive;
}

.submenuRuta:hover{
    color: #e4e4df !important;
    background-color: #6e5b4b !important;
    cursor: pointer;
}


.submenuRutaText{
    font-size: 2vh;
}

.submenuDificultad{
    background-color: #e4e4df !important;
    height: 100%;
    text-align: center;
    padding-top: 0.5rem;
}

.dificultadRutaMini{
    width: auto;
    height: 80px;
}

/*mobile*/
@media screen and (max-width: 600px) {
    .dificultadRutaMini{
        width: auto;
        height: 30px;
        /*center vertically*/
        margin-top: 20px;
    }
}


.informacionBasicaRuta{
    padding-top:2rem;
}

.informacionBasicaTitulo{
    font-family: Bebas Neue,cursive;
    color:#4f3e29 !important;
    font-weight: normal !important;
    font-size: 6.5vh !important;
}


.subTituloInformacion{
    color:#4f3e29 !important;
    font-weight: bold !important;
}

.informacionBasicaContainerUbicacion{
    display: inline-block;
    text-align: left !important;
}


.ubicacionRutaMini{
    width: auto;
    height: 30px;
}

.caminanteRuta{
    height: 80px;
    width: auto;
}

/*mobile*/
@media screen and (max-width: 600px) {
    .caminanteRuta{
        padding-top: 20px;
    }
}

.informacionBasicaRutaTexto{
    text-align: justify;
}

/*mobile*/
@media screen and (max-width: 600px) {
    .informacionBasicaRutaTexto{
        padding-left: 2rem !important;
        padding-top: 20px;
    }
    .containerInformaciones{
        padding-top: 2rem;
    }
}




/*tecnica*/

.fichaTitulo{
    padding: 2px;
    text-align: center;
    font-family: Bebas Neue,cursive;
    background-color: #ebb255;
}

.fichaTituloTexto{
    color: whitesmoke !important;
    font-weight: normal !important;
    font-size: 3vh !important;
}

.fichaTecnicaRuta{
    border-color: #ebb255;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    height: 100%;
}

.fichaContenido{
    padding: 10px;
}



.fichaContenidoIcono{
    width: auto;
    height: 40px;
}

/*llevar*/

.fichaTecnicaRutaLlevar{
    height: 100%;
}


.fichaTituloLlevar{
    padding: 2px;
    text-align: center;
    font-family: Bebas Neue,cursive;
    background-color: #8ebd44 !important;
}


.fichaTecnicaRutaLlevar{
    border-color: #8ebd44;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.fichaContenidoTextoLlevar{
    width: 100% !important;
    text-align: justify;
    padding: 10px;
}

/*advertencias*/

.fichaTecnicaRutaAdvertencias{
    height: 100%;
}


.fichaTituloAdvertencias{
    padding: 2px;
    text-align: center;
    font-family: Bebas Neue,cursive;
    background-color: #f1634d !important;
}


.fichaTecnicaRutaAdvertencias{
    border-color: #f1634d;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

/*Menu Lateral*/
.menuLateral{
    background-color: #e4e4df !important;
    height: 85vh;
    overflow-y: auto;
}

.tituloMenuLateral{
    font-family: Bebas Neue,cursive;
    color: #4f3e29 !important;
    font-weight: normal !important;
    text-align: center;
    font-size: 3vh !important;
}

.rutasMenuLateral{
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.rutaMenuLateral{
    padding-top:1rem
}

.rutaMenuLateral:hover{
    cursor: pointer;
}



/**/

.fichaContenidoAdvertenciaIcono{
    width: auto;
    height: 100px !important;
}

.tituloWikiloc{
    padding: 1rem;
    background-color: #5f9c3d !important;
    display: block;
}

.wikilocLogo{
    width: auto;
    height:30px;
}

.mapaWikiloc{
    position:absolute;top:0;left:0;width:100%;height:100%;
}

.informacionUbicacion{
    color:#4f3e29 !important;
    text-align: justify;
}

.comoLlegarContent{
    padding: 1rem;
}

.iconoComoLlegar{
    text-align: center;
    color: #4f3e29 !important;
}

.tarjetaGuias{
    background-color: #6fbe5d !important;
    color: whitesmoke !important;
    padding: 1rem;
}

.tituloGuias{
    color: whitesmoke !important;
    font-weight: bold !important;
}

.guiaIcono{
    width: auto;
    height: auto;
    color: whitesmoke !important;; 
}

.subTextoMenuLateral{
    text-align: center;
    text-decoration: underline;
    padding: 1rem;
    
}

.subTextoMenuLateral:hover{
    cursor: pointer;
    font-weight: bold;
}

.comoEsLaVereda{
    padding: 2rem;
}


/**/
.iconoComoEsLaVereda{
    height: auto;
    width: 100%;
}

.tabsComoEsLaVereda{
    min-height: 40vh;
}

.imagenCarouselRutaContainer{
    width: 100%;
    height: 50vh;
}

.galeriaDeFotos{
    padding: 2rem;
}

.galeriaFotos{
    max-height: 65vh;
    text-align: center !important;
    display: block;
    overflow-y: auto;
}

.imagenCarouselRuta{
    width: auto;
    height: 100%;
}

.miniImagenCarousel{
    padding-bottom: 2rem;
    opacity: 0.5;
    cursor: pointer;
}

.miniImagenCarousel:hover{
    opacity: 1;
}

.miniImagenCarousel:active{
    cursor: pointer;
    opacity: 1;
}

.ultimaActualizacion{
    font-family: Bebas Neue,cursive;
    color:#4f3e29 !important;
    font-weight: normal !important;
    font-size: 3.5vh !important;
    text-align: right;
}

