.icon{
    width: auto;
    height: 20px;
    padding: 2px;
}

.difficulty{
    width: auto;
    height: 45px;
    padding: 2px;
}