.navbar{
    background-color:rgb(253, 188, 73) !important;
}

.container{
    margin: auto;
    display: flex;
    height: 50px;
    position:relative;

    width: clamp(16rem, 90vw, 70rem);
}

/*medium screens*/
@media (max-width: 1200px) {
    .container{
        padding-left: 2rem ;
        padding-right: 2rem ;
    }
}
.logoImg{
    width: 300px;
    margin: auto;
    padding-top: 10px;   
}

.logoImg:hover{
    cursor: pointer;
}

@media (max-width: 600px){
    .logoImg{
        width: min(250px, 90%);
    }
}

.innerElements{
    position:absolute;
    right: 0;
}

.buttonNav{
    font-family: 'poppins';
    font-weight: 600;
    font-size: 24px;
    color: #64503F ;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    padding-left: 1rem;
    padding-right: 1rem;
}

.buttonNav:hover{
    color: whitesmoke ;
    background-color:rgb(253, 188, 73) ;
}


.selected{
    color: whitesmoke;
    margin-top: -35px;
    height: 5px;
    background: transparent;
    border-radius: 10px;
    box-shadow: 0px 35px 0 0 whitesmoke;
}

@media (max-width: 950px) {
    .innerNavbar{
        right: 5%;
    }
}

.li{
  display: inline-block;
  margin-top: -15px;
}

.li:hover{
    cursor: pointer;
}

.menuIcon{
    position: absolute;
    right: 30px;
    top: 5px;
    display: none;
}

@media (max-width: 900px){
    .innerElements{
        display: none;
    }
    .menuIcon{
        display:block;
    }
}