.newsletter{
    background-color: #f5af3e;
}

.newsletterContent{
    padding-left: 5.5rem;
    padding-right: 5.5rem;
    padding-top: 1rem;
}

.newsletterContent{
    font-family: "Bebas Neue",cursive;
    font-weight: normal !important;
    color: whitesmoke !important;
}

.newsletterTitle{
    color: whitesmoke !important;
    font-size: 3vh !important;
    text-align: center;
}

.footer{
    background-color: #c67244;
}

.footerContent{
    padding-left: 10rem;
    padding-right: 10rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

/*On Mobile*/
@media screen and (max-width: 600px) {
    .newsletterContent{
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1rem;
    }

    .newsletterTitle{
        font-size: 2vh !important;
        text-align: left !important;
    }
    .footerContent{
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.logobottom{
    width: auto;
    height: 10vh;
    margin-bottom: 1rem;
}

.titleFooter{
    color: whitesmoke !important;
    font-size: 2.5vh !important;
    font-family: Bebas Neue,cursive !important;
}

.subTextFooter{
    color: whitesmoke !important;
    text-decoration: none;
}

.subTextFooter:hover{
    font-weight: bold;
    text-decoration: none;
    color: whitesmoke !important;
}

.subTextFooter:visited{
    color: whitesmoke !important;
    text-decoration: none;
}

.subTextFooter:active{
    color: whitesmoke !important;
    text-decoration: none;
}

.emailFooter{
    color:whitesmoke !important;
    text-decoration: none;
}

.emailFooter:hover{
    font-weight: bold;
    text-decoration: none;
}