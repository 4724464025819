.drawerMobile{
    background-color: rgb(253, 188, 73) !important;
    color: #64503F;
    height: 100%;
    font-weight: bold !important;
    width: 240px;
    text-align: center;
}

.logoImg{
    width: 80%;
    height: 60%;
    margin: auto;
    padding-top: 10px;
}

.logoImg:hover{
    cursor: pointer;
}

.lineaIzquierda{
    margin-top: 30px;
    width: 5px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    z-index: 1;
    margin-right: 0px;
}

.options_mobile_drawer{
    font-family: 'poppins';
    font-weight: 600;
    font-size: 32px;
    color: #64503F;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    padding-left: 10px;
}

.options_mobile_drawer:hover{
    cursor: pointer;
}