.container{
    width: 100%;
    height: 100%;
    
}

.cuadroEnmedio{
    background-color: gray;
    width: auto;
    height: auto;
    margin: auto;
    margin-top: 30vh;
}
