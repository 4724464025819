.parallax-text{
    color:whitesmoke !important;
    font-size: 5vh !important;
}

.parallax-text1{
    color:whitesmoke !important;
    font-size: 10vh !important;
}

.ant-btn-primary {
    border-color: #3f8dc8 !important;
    background: #3f8dc8 !important;
}

.ant-typography{
    text-transform: uppercase !important;
}

.ant-select-selection-item{
    text-transform: uppercase !important;
    color:#64503f !important;
    font-weight: 500;
}

.contentRutas{
    color: #64503f !important;
    padding: 50px 150px !important;
}

.subtitle{
    color: #64503f !important;
    font-weight: normal !important;
    font-size: 6vh !important;
}

.tarjetas-rutas{
    padding-top: 2rem;
}

/*on mobile*/
@media (max-width: 768px) {
    .contentRutas{
        padding: 25px 75px !important;
    }
}